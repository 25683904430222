import React from "react";
import './about.css'
import Mission from '../containers/mission/Mission'
import Products from '../containers/products/Products'
  
const About = () => {
  return (
    <div className="PI__About ">
      <Mission />
      <Products />
    </div>
  );
};
  
export default About;