
import React, { useRef, useEffect, useState } from 'react';
import Results from '../components/list/List';
import MapHeader from '../components/map/Header';

import './map.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {GetMarkerData, GetSegmentData} from '../api';
//import testData from '../data/test/geoJson.dat';

 
mapboxgl.accessToken = 'pk.eyJ1IjoibWlib2tlc2NoIiwiYSI6ImNrZWYzMWw3bjExczEycnAzYmZqNDdxMGMifQ.aJftmCm_oEXrn2NRjsckIw';


const GetMarkerIcons = () => {
  const markerIconList = [{ id: "5", source: "./images/icons/washroom-marker-icon.png", mapID: "" },
  { id: "6", source: "./images/icons/refreshment-marker-icon.png", mapID: "" },
  { id: "7", source: "./images/icons/photo-marker-icon.png", mapID: "" },
  { id: "8", source: "./images/icons/hazard-marker-icon.png", mapID: "" },
  { id: "9", source: "./images/icons/water-fountain-marker-icon.png", mapID: "" },
  { id: "10", source: "./images/icons/skate-park-marker-icon-2-35px.png", mapID: "" },
  { id: "11", source: "./images/icons/street-spot-marker-icon.png", mapID: "" },
  { id: "12", source: "./images/icons/street-spot-marker-icon.png", mapID: "" },
  { id: "13", source: "./images/icons/street-spot-marker-icon.png", mapID: "" }];

  return (markerIconList);
};

async function LoadImagesForMarkers (map,list) {
  
  list.forEach(item => {
    //console.log(item.source)
    map.loadImage(item.source,(error, image) => {
      if (error) 
        console.log("error loading marker image:: " + item.source)
      else
        map.addImage(item.id, image);
      }
    );
  });
  
}

/// testing
const testResultData = 
{title:'title1', 
description:'description1',
images:[
  'link1.jpg',
  'link2.jpg'
]}
;

////////////////



const Map = () => {
  
  const mapContainer = useRef(null);
  //const resultsContainer = useRef(null);
  let map = useRef(null);
  
  const [resultData,setResultData] = useState([]);
  const [lng, setLng] = useState(-123.15);
  const [lat, setLat] = useState(49.3);
  const [zoom, setZoom] = useState(11);

  const [testTitle,setTitle] = useState('');
  const [segmentDetailData,setSegmentResults] = useState([])
  const [markerDetailData,setMarkerResults] = useState([])
  const [showResults, setShowResults] = useState(0);
  

  //const [markers,SetMarkers] = useState([]);
  const iconList = GetMarkerIcons();

  useEffect(() => {
    if (map.current) return; // initialize map only once
    console.log('Starting Map');
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });

    GetSegmentData()
    .then((data) => {
      //updateResultData(data);
      //setResultData({title:data.features[0].properties.name});
      AddSegmentsToMap(map,data);
    })
    .then(() => {
      GetMarkerData()
      .then((data) => {
        //setResultData({description:data.features[0].properties.description});
        AddMarkersToMap(map,data);
      })
    })
    .then(() => {
      AddMarkerClickEvents(map,[markerDetailData,setMarkerResults],[showResults,setShowResults]);
      AddSegmentClickEvents(map,[segmentDetailData,setSegmentResults],[showResults, setShowResults]);
    })

  },[]);

  useEffect(()=>{
    setResultData(segmentDetailData);
  },[segmentDetailData])

  useEffect(()=>{
    setResultData(markerDetailData);
},[markerDetailData])

  

  return ( 
    <>
      <div className='PI__Map section__padding'>
        <MapHeader></MapHeader>
        <div className='PI__Map-container'>
          <div className={`PI__Map-container_list ${showResults >0 ? 'PI__Show' : 'PI__Hide'}`}>
            <Results data={resultData} show={setShowResults} />
          </div>
          <div ref={mapContainer} className="PI__Map-container_map">
          </div>
        </div>
      </div>
    </>

  );
};
  
export default Map;


function AddMarkerClickEvents(map,[markerDetailData,setMarkerResults],[showResults, setShowResults]){
  map.current.on('click','markers', (e) =>{
    console.log('marker clicked');
    setMarkerResults(e.features[0].properties);
    setShowResults(1);
  })

  map.current.on('mouseenter', 'markers', () => {
    map.current.getCanvas().style.cursor = 'pointer';
  });
     
  // Change it back to a pointer when it leaves.
  map.current.on('mouseleave', 'markers', () => {
    map.current.getCanvas().style.cursor = '';
  });
}

//function AddSegmentClickEvents(map, [segmentDetailData,setSegmentResults]){
function AddSegmentClickEvents(map, [segmentDetailData,setSegmentResults], [showResults, setShowResults]){
  map.current.on('click','segments', (e) =>{
    console.log('segment clicked');
    setSegmentResults(e.features[0].properties);

    setShowResults(1);
    //console.log(showResults)
  })

  map.current.on('mouseenter', 'segments', () => {
    map.current.getCanvas().style.cursor = 'pointer';
  });
     
  // Change it back to a pointer when it leaves.
  map.current.on('mouseleave', 'segments', () => {
    map.current.getCanvas().style.cursor = '';
  });
}



function AddSegmentsToMap(map,data){
  map.current.once('load', function (e) {
    //LoadImagesForMarkers(map.current,iconList);

    map.current.addSource('segments',{
      'type': 'geojson',
      'data': data,
      generateId: true
    });
    map.current.addLayer({
      'id': 'segments',
      'type': 'line',
      'source': 'segments',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
      'paint': {
        'line-color': ['get', 'color'],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],	10,	7]
        
      }
    });
  });
}

function AddMarkersToMap(map,data){

  map.current.once('load', function (e) {
    LoadImagesForMarkers(map.current,GetMarkerIcons());

    map.current.addSource('markers',{
      'type': 'geojson',
      'data': data,
      generateId: true
    });
    map.current.addLayer({
      id: 'markers',
      source: 'markers',
      type: 'symbol',
      layout: {
        // full list of icons here: https://labs.mapbox.com/maki-icons
        'icon-image': ['get', 'markerType'],	
        'text-offset': [0, 1],
        'text-anchor': 'top',
        'icon-allow-overlap': true,
      }
    });
  });
}
