
import React from 'react';
import './donate.css'

const Donate = () => {
  return (
    <div className='PI__Donate section__padding'>
      <h1>Donate</h1>
    </div>
  );
};
  
export default Donate;