import React from 'react'

import {Header, Footer} from './containers';
import { Navbar } from './components';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from './pages';
import About from './pages/about';
import Learn from './pages/learn';
import Gear from './pages/gear';
import Donate from './pages/donate';
import Map from './pages/map';
import Contact from './pages/contact';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' exact element={<Home />} />
        <Route path='/map' element={<Map />} />
        <Route path='/learn' element={<Learn />} />
        <Route path='/gear' element={<Gear />} />
        <Route path='/donate' element={<Donate />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </Router>

    
  )
}

export default App

