import React from 'react';
import './contact.css';
import Article from '../components/article/Article'
import youtubeIcon from '../assets/youtube-icon.png'
import instagramIcon from '../assets/instagram-icon.png'
import emailIcon from '../assets/emailIcon.png'

const contactData = [
  {
    id:'con01',
    title:'YouTube',
    content:'',
    imgSrc:youtubeIcon,
    link:'https://www.youtube.com/channel/UCkOHVcDwPyaiYE1oka6zdEw',
    linkTitle:'PathInspectors'
  },
  {
    id:'con02',
    title:'Instagram',
    content:'',
    imgSrc:instagramIcon,
    link:'https://www.instagram.com/pathinspectors/',
    linkTitle:'@PathInspectors'
  },
  {
    id:'con03',
    title:'Email',
    content:'Info@PathInspectors.com',
    imgSrc:emailIcon,
    link:'',
    linkTitle:''
  }
]
const Contact = () => {
  
  return (
    <>
    <div className='PI__Contact section__padding gradient__bg'>
      <h1 className='gradient__text'></h1>
      {contactData.map((item) => (
        <>
          <Article
            key={item.id}
            id={item.id}
            title={item.title}
            content={item.content}
            imgSrc={item.imgSrc}
            link={item.link}
            linkText={item.linkTitle} />
        </>
      ))}
    </div><div className='section__margin'>

      </div>
    </>
    
  );
};
  
export default Contact;