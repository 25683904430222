import axios from 'axios';

const markerBaseUrl = 'https://pathinspectors.com/api2/markers/read';
const markerFullUrl = 'http://pathinspectors.com/api2/markers/read?lat=49.285&lon=-123.129&type=all&distance=1&maxResults=500&isGeo=1&api_key=983f58036bca0f2d8d33ef5b3bacccd0ae4b246e'

const segmentFullUrl = 'https://pathinspectors.com/api2/segments/read?lat=49.285&lon=-123.129&green=1&blue=1&black=1`&closed=1&distance=1&maxResults=100&isGeo=1&api_key=983f58036bca0f2d8d33ef5b3bacccd0ae4b246e'

export const GetMarkerData = async () =>{
    //console.log(fullUrl);
    try{
        const response = await axios.get(markerFullUrl, { 
            headers: {}
          });
        return response.data;

    } catch(error){
        console.log(error)
    }
}

export const GetSegmentData = async () =>{
    try{
        const response = await axios.get(segmentFullUrl, { 
            headers: {}
          });
        return response.data;

    } catch(error){
        console.log(error)
    }
}