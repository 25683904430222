import React from 'react'
import './footer.css'
import logo from '../../assets/watermark1.png';


const Footer = () => {
  return (
    <div className="PI__footer section__padding">
    <div className="PI__footer-heading">
      <h1 className="gradient__text">Live. Skate. Smile.</h1>
    </div>

    <div className="PI__footer-btn">
      <p>Get All The Updates</p>
    </div>

    <div className="PI__footer-links">
      
      <div className="PI__footer-links_div">
        <h4>Links</h4>
        <p>Interactive Map</p>
        <p>Product Reviews</p>
        <p>Lessons and Training</p>
        <p>About Us</p>
        <p>Contact Us</p>
      </div>
      <div className="PI__footer-links_div">
        <h4>Company</h4>
        <p>About Us</p>
        <p>Contact Us</p>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        
      </div>
      <div className="PI__footer-links_div">
        <h4>Get in touch</h4>
        <p>Youtube</p>
        <p>Instagram</p>
        <p>Join the Mailing List</p>
      </div>
    </div>

    <div className="PI__footer-copyright">
      <div className="PI__footer-links_logo">
        <img src={logo} alt="Path Inspectors Logo" />
      </div>
      
      <p>@2021 Path Inspectors. All rights reserved.</p>
    </div>
  </div>
  )
}

export default Footer
