import React from 'react'
import '../../components/cta/cta.css'

const Learn = () => {
  return (
    <div className='PI__CTA section__margin'>
      <div className="PI__CTA-content">
        <h3>Want to skate like a Pro?</h3>
        <p>Register Today & learn from the best.</p>
      </div>
      <div className="PI__CTA-btn">
        <button type="button">Get Started</button>
      </div>
    </div>
  )
}

export default Learn
