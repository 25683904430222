
import React from 'react';
import './gear.css'
  
const Gear = () => {
  return (
    <div className='PI__Gear section__padding'>
      <h1>Gear reports and reviews</h1>
    </div>
  );
};
  
export default Gear;