import React from 'react'
import './newsElement.css'
import { NavLink } from "react-router-dom";


import newsIcon from '../../assets/newsIcon.png'

const NewsElement = ({title, content, date, link, linkText}) => {
  return (
    <div className='PI__NewsElement'>
        <div className='PI__NewsElement-newsIcon'>
            <img src={newsIcon} alt={title} />
        </div>
        <div className='PI__NewsElement-content'>
            <div className='PI__NewsElement-date'>
                <p>{date}</p>
            </div>
            <div className='PI__NewsElement-title'>
                <p>{title}</p>
            </div>
            <p>{content}</p>
           
            {link != ''
                ?  <p className='PI__NewsElement-link'>
                < NavLink className='PI__NewsElement-link' to={link}>{linkText}</NavLink>
                </p>
                : null
            }
           
            
        </div>
    </div>
  )
}

export default NewsElement
