import React from 'react';
//import people from '../../assets/people.png';
import intoImage from '../../assets/road-map.png';
import './intro.css';

const Intro = () => (
  <div className="PI__Intro section__padding" id="intro">
    <div className="PI__Intro-image-header">
      <img src={intoImage} />
    </div>
    <div className="PI__Intro-content">
      <h1 className="gradient__text">Live. Skate. Smile.</h1>
      <p>Weather you're new to skating and looking to improve, looking for skate gear, or a veteran looking for something new and exciting, we got you covered.</p>
      <p>
        Where can I skate? 
      </p>
      <p>
        There are pathways all around us.  Some are flat and smooth while others can be steep and rough.  We are on a mission to map out our urban environments from the viewpoint of Inline Skating.
      </p>
      <p>
        Everything in life is better when we're prepared.
      </p>

      <div className="PI__Intro-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Keep Me Updated</button>
      </div>

    </div>

    <div className="PI__Intro-image-full">
      <img src={intoImage} />
    </div>
  </div>
);

export default Intro;