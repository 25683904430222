import React from 'react'
import './products.css'
import { Feature } from '../../components';

import productImage from '../../assets/product-info-map.png';
import imgSrc2 from '../../assets/productBanner2-small.jpg'; 
import imgSrc3 from '../../assets/productBanner2-small.jpg'; 
const Products = () => {
  return (
    <div className='PI__Products section__padding'>
        <div className='PI__Products-content'>
          < Feature 
            title="Interactive Map" 
            content="We are currently mapping out all of the pathways we can find.  View the interactive map to plan your next skate" />

          < Feature 
            title="Learning" 
            content="Learn from the best.  Private individual and group lessons are available" />

          < Feature 
            title="Product Guides" 
            content="We test it so you don't have to" />
        </div>
    </div>
  )
}

export default Products
