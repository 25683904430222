import React from 'react'
import './sponsors.css'

const Sponsors = () => {
  return (
    <div>
        Sponsors
    </div>
  )
}

export default Sponsors
