import React from 'react';
import './feature.css';

const Feature = ({imageSource, title, content }) => (
  <div className="PI__Feature-container">
    <div className="PI__Feature-container-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className='PI__Feature-container-image'>
      {imageSource && (
        <img src={imageSource} alt='title' />
      )}
    </div>
    <div className="PI__Feature-container-text">
      <p>{content}</p>
    </div>
  </div>
);

export default Feature;