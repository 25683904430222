import React from "react";
//import {AutoComplete} from '@react-google-maps/api';
import {AppBar, Toolbar, Typography, InputBase, Box} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const Header = () => {
    return (
        <AppBar position ="static">
            <Toolbar className=''>
                <Typography variant='h5' className="">
                    Difficulty Filters
                </Typography>
                <Box display="flex">
                <Typography variant='h5' className="">
                   Rating Filter
                </Typography>
                </Box>
               
                <Box display="flex">
                    <Typography variant='h6' className="">
                        Explore
                    </Typography>
                    <div className='Autocomplete'>
                        <div className="">
                            <div className="">
                                <SearchIcon />
                            </div>
                            <InputBase placeholder="Search..." classes={{}} />
                        </div>
                    </div>
                </Box>
            </Toolbar>

        </AppBar>
    );
}

export default Header;
