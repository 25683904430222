import React from 'react'
import './article.css'
import {Link} from 'react-router-dom';

const Article = ({title,content,imgSrc, link, linkText, id}) => {
  return (
    <div className='PI__Article ' key={id}>
      <img src={imgSrc} alt={title} />
      <h2>{title}</h2>
      {content &&
       <p>{content}</p>
      }
      { link &&
        <p><a href={link} alt={linkText}>{linkText} </a></p>
      }
      
    </div>
  )
}

export default Article
