import React from 'react'
import './review.css'

const Review = ({content,image}) => {
  return (
    <div className='PI__Review'>
        <div className='PI__Review_image'>
            <img src={image} alt="Reviwers Icon" />
        </div>
        <div className='PI__Review_content'>
           <p>{content}</p> 
        </div>

    </div>
  )
}

export default Review