import React from 'react'
import './news.css'

import NewsElement from '../../components/news/NewsElement'
import sectionImage from '../../assets/newsElementIcon.png'

const newsData = [
  {
    id:'001',
    title:'New Website In The Works',
    content:'We are working hard to redesign our website.  Hopefully, we are able to test and launch this new version before summer.  Keep Skating - Mike B',
    date:'February 16, 2022',
    link:'',
    linkText:''
  },
  {
    id:'002',
    title:'Test',
    content:'We are testing hard to test our website.  Hopefully, we are able to test and test this new test before testing.  Keep testing - test B',
    date:'February 15, 2022',
    link:'/map',
    linkText:'View on Map'
  }
]

const News = () => {
  return (
    <div className='PI__News section__padding gradient__bg'>
        <div className='PI__News-title gradient__text'>
          <p>Latest News</p>
        </div>
        {newsData.map((item,index) => (
          <>
            <div className='PI__News-bar' key={index}></div>
            <NewsElement
              key={item.id} 
              title={item.title} 
              content={item.content}
              date={item.date} 
              link={item.link}
              linkText={item.linkText}
            />
          </>
          
        ))}
      
      
    </div>
  )
}

export default News
