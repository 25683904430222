import React, {useEffect, useState} from 'react'
import './navbar.css'
import {RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { NavLink, Link } from "react-router-dom";
import logo from '../../assets/watermark1.png';

const Menu = (props) => (
  <>
    <p><Link 
          className='PI__Navbar-link' 
          to='/'
          onClick={() => props.toggle(false)} >
            Home
        </Link>
    </p>
    <p>< Link 
          className='PI__Navbar-link' 
          to='/Map'
          onClick={() => props.toggle(false)} >
            Interactive Map
        </Link>
    </p>
    {/*<p>< NavLink className='PI__Navbar-link' to='/Learn'>Learning</NavLink></p>
    <p>< NavLink className='PI__Navbar-link' to='/Gear'>Gear Guide</NavLink></p>*/}
    <p>< Link className='PI__Navbar-link' to='/About' onClick={() => props.toggle(false)}>About</Link></p>
    <p>< Link className='PI__Navbar-link' to='/Contact' onClick={() => props.toggle(false)}>Contact</Link></p>
    {/*<p>< NavLink className='PI__Navbar-link' to='/Donate'>Donate</NavLink></p>*/}

  </>
)



const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (

    <div className='PI__navbar section__shadow'>
      <div className='PI__navbar-links_logo'>
        <img src={logo} alt="logo" ></img>
      </div>
      <div className='PI__navbar-links'>
        
        <div className='PI__navbar-links_container'>
          <Menu toggle={()=>setToggleMenu}/>
        </div>
      </div>
      
      <div className='PI__navbar-menu'>
        {toggleMenu
        ? <RiCloseLine color='#fff' size={45} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color='#fff' size={45} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='PI__navbar-menu_container scale-up-tr'>
            <div>
              <Menu toggle={setToggleMenu} />
              <div className='PI__navbar-menu_container-links-sign'>
                <p>Sign In</p>
                <button type='button'>Donate</button>
              </div>
              
            </div>
          </div>
        )}
      </div>

    </div>
  )
}

export default Navbar
