import React from 'react'
import '../../components/cta/cta.css'

const ProductCTA = () => {
  return (
    <div className='PI__CTA section__margin'>
      <div className="PI__CTA-content">
        <h3>Looking for new Gear?</h3>
        <p>Having a hard time choosing which gear is right for you?  Check out our product reviews to get the information you need before making a purchase.</p>
      </div>
      <div className="PI__CTA-btn">
        <button type="button">Gear Reviews</button>
      </div>
    </div>
  )
}

export default ProductCTA
