import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './list.css';

const List = (props) =>{
    var images = null;
    var galleryImages = [];
    const rootImagePath='https://pathinspectors.com/'
    try{
        images = props.data.images;
        images.replace("\\","");
        
        images = JSON.parse(images);
        images.map((item) => {
            if(item != '')
                galleryImages.push({original: rootImagePath + item})
        })
    } catch(e){
        //console.log(e);
    }

    return (
        <>
        <div className="PI__ResultsCard">
            <h1>{props.data.title == null ? props.data.name : props.data.title}</h1>
            <p>{props.data.description}</p>

            {galleryImages.length > 0
                ? <ImageGallery showThumbnails={false} items={galleryImages} />
                :''
            }
            <div className="PI__ResultsCard-close">
                <p>
                    <a onClick={()=>props.show(0)}>&lt;&lt;Close Results</a>
                </p>
            </div>
            
        </div>
        </>
    )
}

export default List;