import React from 'react'
import './reviews.css'

import Review from '../../components/review/Review'

import image from '../../assets/possibility.png';
import reviewIcon1 from '../../assets/reviewIcon1.png';
import reviewIcon2 from '../../assets/reviewIcon1.png';
import reviewIcon3 from '../../assets/reviewIcon1.png';
import reviewIcon4 from '../../assets/reviewIcon1.png';

const reviewData = [
  {
    image: reviewIcon1,
    content: "A great little shop with SO much knowledge. Really good service to go along with that experience too. Definitely will be buying my next pair of skates there!.",
    id:"rd1"
  },
  {
    image: reviewIcon2,
    content: "Bought my first pair of inline skates in over twenty years. Quentin, listened to what I wanted and made a few suggestions at different price points. I ended up leaving super \"juiced\" with the new pair of skates, and extremely happy with my experience at Shop-Task. I would strongly recommend beginners to professionals to shop here.",
    id:"rd2"
  },
  {
    image: reviewIcon3,
    content: "I love this place. From the display of countless roller blade wheels in the windows to the cheerful staff inside the Shop Task Skates could not be more perfect. I go there to get ABEC bearings. Fantastic deals. You won't be disappointed.",
    id:"rd3"
  }
]

const Reviews = () => {
  return (
    <div className="PI__Reviews section__padding " id="possibility">
      <div className="PI__Reviews-image">
        <img src={image} alt="possibility" />
      </div>
      <div className="PI__Reviews-content">
        {reviewData.map((item,index) => (
          <Review image={item.image} content={item.content} key={item.id}/>
        ))}
      </div>
    </div>
  )
}

export default Reviews
