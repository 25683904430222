
import React from 'react';
import './learn.css'
  
const Learn = () => {
  return (
    <div className='PI__Learn section__padding'>
      <h1>Learning Center</h1>
    </div>
  );
};
  
export default Learn;