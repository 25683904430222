import './index.css';
import React from 'react';
import {Intro, LearnCTA, ProductCTA, Mission, News, Reviews, Sponsors, Products} from '../containers';
import { Navbar } from '../components';
  
const Home = () => {
  return (
    <div className='App'>
        <div className='gradient__bg'>
            <Intro />
        </div>
        <Products />
        <LearnCTA />
        <News />
        
        <ProductCTA />
        <Reviews />
        <Sponsors />
        
        
    </div>
  );
};
  
export default Home;