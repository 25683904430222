import React from 'react'
import './mission.css'

import image from '../../assets/missionStatement.jpg'

const Mission = () => {
  return (
    <div className='PI__Mission section__padding gradient__bg'>
      <div className='PI__Mission-image'>
        <img src={image} alt="Mission Statement"></img>
      </div>
      <div className='PI__Mission-content'>
          <h1 className="gradient__text">Our Mission</h1>
          <p>
            The Path Inspectors project is aimed at providing the 'small wheeled' sports community (Roller Blades, Roller Skates, Skate Boards, Scooters, etc) with information to improve your rolling experience.
          </p>
          <p>
            Pathway Inspection Reports, Skate Park Locator, Lessons and Training material and Gear Reviews. 
          </p>
       </div>
    </div>
  )
}

export default Mission
